import React from "react";
import Car from "@shared/lib/interfaces/car.interface";
import VerticalCatalogCard from "../VerticalCatalogCard/VerticalCatalogCard";
import classes from "./CardsList.module.scss";
import { Banner } from "../CatalogList/types";

export interface CardsListProps {
    productsData: Car[];
    banners?: Banner;
}

const CardsList = ({ productsData, banners }: CardsListProps) => {
    return (
        <div className={classes.CardsList}>
            <ul className={`${classes.CardList} ${classes.Vertical}`}>
                {productsData.map((item, index) => {
                    return (
                        <li key={index}>
                            <VerticalCatalogCard data={item} banners={banners} />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
export default CardsList;