import { useEffect, useMemo, useRef, useState } from "react";
import { useWindowSize } from "./useWindowsSize";

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(false);
    const size = useWindowSize();
    useEffect(() => {
        if (size.width) {
            if (size.width < 600) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        }
    }, [size]);

    return isMobile;
};

export default useIsMobile;