import React from "react";
import SvgInterface from "@shared/lib/interfaces/SvgInterface";

/* eslint-disable react/display-name */
export default React.memo(function AutoIcon({ width, height, color, ...other }: SvgInterface) {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="11" cy="12" r="7.25" stroke={color || "black"} strokeWidth="1.5" />
            <path
                d="M8.61227 16V8H11.8156C12.8389 8 13.6434 8.22857 14.2292 8.68571C14.8224 9.13524 15.119 9.80191 15.119 10.6857C15.119 11.5619 14.8224 12.2286 14.2292 12.6857C13.6434 13.1352 12.8389 13.36 11.8156 13.36H9.4131L10.0582 12.6743V16H8.61227ZM10.0582 12.8343L9.4131 12.16H11.8045C12.3977 12.16 12.8537 12.0343 13.1726 11.7829C13.4988 11.5238 13.662 11.1619 13.662 10.6971C13.662 10.2324 13.4988 9.8781 13.1726 9.63429C12.8537 9.38286 12.3977 9.25714 11.8045 9.25714H9.4131L10.0582 8.52571V12.8343ZM7.5 14.8114V14.0114H12.5052V14.8114H7.5Z"
                fill={color || "black"}
            />
        </svg>
    );
});