import Link from "next/link";
import Logo from "../../components/UI/Logo/Logo";
import { CURRENT_YEAR } from "@shared/config/Date";
import { SLOGAN_ADDITIONAL_TEXT, SLOGAN_TEXT } from "@shared/config/GlobalText";
import classes from "./Footer.module.scss";
import { CONTACTS, COPYRIGHT_NOTICE, DOCUMENTS } from "./constants";
import { RouterConfig } from "@shared/lib/routerConfig";

const Footer = () => {
    return (
        <div className={classes.Footer}>
            <div className={classes.Container}>
                <div className={classes.Row}>
                    <div className={classes.Col}>
                        <Logo width={205} height={30} />
                        <div className={classes.UnderLogoText}>
                            {SLOGAN_TEXT}
                            <br /> {SLOGAN_ADDITIONAL_TEXT}
                        </div>
                    </div>

                    <div className={`${classes.Col} ${classes.SiteMapCont}`}>
                        <label>{DOCUMENTS.LABEL}</label>
                        <ul className={classes.SiteMap}>
                            <li>
                                <Link target={"_blank"} href={RouterConfig.PRIVACY_POLICY}>
                                    {DOCUMENTS.PRIVACY_POLICY_TEXT}
                                </Link>
                            </li>
                            <li>
                                <Link target={"_blank"} href={RouterConfig.TERMS_OF_USE}>
                                    {DOCUMENTS.TERMS_OF_USE_TEXT}
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div className={`${classes.Col} ${classes.ContactsCont}`}>
                        <label>{CONTACTS.LABEL}</label>
                        <ul className={classes.Contacts}>
                            <li>
                                {CONTACTS.SUPPORT.TEXT} -{" "}
                                <a href={`mailto:${CONTACTS.SUPPORT.ADDRESS}`}>
                                    {CONTACTS.SUPPORT.ADDRESS}
                                </a>
                            </li>
                            <li>
                                {CONTACTS.COMMERCE.TEXT} -{" "}
                                <a href={`mailto:${CONTACTS.COMMERCE.ADDRESS}`}>
                                    {CONTACTS.COMMERCE.ADDRESS}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={`${classes.Row} ${classes.BottomRow}`}>
                    {CURRENT_YEAR} {COPYRIGHT_NOTICE}
                </div>
            </div>
        </div>
    );
};

export default Footer;