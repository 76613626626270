import React from "react";
import SvgInterface from "@shared/lib/interfaces/SvgInterface";

/* eslint-disable react/display-name */
export default React.memo(function AutoIcon({ width, height, color, ...other }: SvgInterface) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.89617 7.69433L9.2942 5.29626C9.48467 5.10857 9.73962 5.00313 10.0053 5.00313C10.27 5.00313 10.525 5.10857 10.7125 5.29626C10.9001 5.48394 11.0055 5.7385 11.0055 6.00393C11.0055 6.26935 10.9001 6.52391 10.7125 6.7116L8.31448 9.10717C8.10935 9.31259 7.86613 9.47557 7.59848 9.58678C7.32987 9.69798 7.04269 9.75523 6.75258 9.75523C6.46247 9.75523 6.17529 9.69798 5.90765 9.58678C5.63903 9.47557 5.39581 9.31259 5.19166 9.10717L4.29594 8.21195C4.19924 8.11991 4.12207 8.00944 4.06835 7.88705C4.0156 7.76466 3.98728 7.63283 3.98532 7.49934C3.98337 7.36583 4.00876 7.23337 4.05956 7.10975C4.09179 7.02879 4.13575 6.9527 4.18849 6.88333C4.21682 6.84677 4.2471 6.81208 4.27934 6.77952C4.37311 6.6852 4.48544 6.61075 4.60949 6.56056C4.73355 6.51037 4.86541 6.48545 4.99923 6.48728C5.13306 6.4891 5.26492 6.51763 5.38702 6.57119C5.50912 6.62475 5.6195 6.70224 5.71132 6.79911L6.60899 7.69433H6.89617Z"
                fill={color || "#1D1D1B"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.0036 9.39223C15.0036 10.9526 14.1332 11.9678 13.7161 12.453C13.1379 13.1281 12.5704 13.6532 11.3054 14.4534C10.8151 14.766 10.1704 15.1311 9.38504 15.5412C8.8019 15.8427 8.15526 16 7.49885 16C6.84342 16 6.19678 15.8427 5.61364 15.5412C4.83611 15.1336 4.19826 14.766 3.69814 14.4534C2.41268 13.6357 1.85298 13.1106 1.35286 12.5255L1.347 12.5182C0.872277 11.9714 0 10.9656 0 9.39473V3.62836C0 3.06131 0.174846 2.50805 0.501096 2.0443C0.827345 1.58056 1.28937 1.229 1.8227 1.03774C3.6454 0.378928 5.5648 0.0282126 7.50178 0C9.43877 0.0295099 11.3582 0.380187 13.1809 1.03774C13.7142 1.22885 14.1752 1.58003 14.5015 2.04328C14.8277 2.50653 15.0036 3.05924 15.0036 3.62586V9.39223ZM7.50569 13.9967C7.83878 13.9967 8.16698 13.9194 8.4649 13.7708C9.1848 13.3932 9.77967 13.0556 10.2349 12.768C11.2976 12.0954 11.7157 11.7153 12.1279 11.2352C12.5401 10.7551 13.0031 10.2149 13.0031 9.39223V3.62336C13.0031 3.5425 12.9894 3.46271 12.964 3.38694C12.9406 3.31767 12.9074 3.25178 12.8644 3.19149C12.7755 3.06524 12.6485 2.96978 12.503 2.9182C10.8981 2.33878 9.20824 2.0287 7.50178 2.00047C5.79532 2.02989 4.10547 2.34168 2.50059 2.92319C2.35505 2.97478 2.22807 3.07025 2.13918 3.19649C2.04931 3.32274 2.00048 3.47359 2.00048 3.62836V9.39473C2.00048 10.2146 2.45371 10.7393 2.80145 11.14L2.81317 11.1551C3.25859 11.6778 3.70303 12.0904 4.77653 12.7705C5.22878 13.0556 5.82658 13.3907 6.54648 13.7708C6.8444 13.9194 7.1726 13.9967 7.50569 13.9967Z"
                fill={color || "#1D1D1B"}
            />
        </svg>
    );
});