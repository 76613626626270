import React, { useEffect, useState } from "react";
import { numberWithSpaces, renderPrice } from "../../../utils/utils";
import { useGetOfferAnalyticsBoundsQuery } from "@app/store/analyticsApi";
import Car from "@src/interfaces/carDetails.interface";
import classes from "./ProductChartLine.module.scss";

export interface ProductChartLineProps {
    offer: Car;
}

const ProductChartLine = ({ offer }: ProductChartLineProps) => {
    const { data, error } = useGetOfferAnalyticsBoundsQuery(offer.id || "", {
        skip: !offer?.id
    });

    const [bias, setBias] = useState<any>({
        percent: 0,
        isNegative: false
    });

    const low = data?.low || 0;
    const hight = data?.high || 0;
    const currentPrice = offer.price;

    const percent = Math.round((hight - low) / 60);

    const leftPercent =
        currentPrice > hight ? 87 : currentPrice < low ? 10 : 20 + (currentPrice - low) / percent; //20 + ((currentPrice - low) / percent);

    const calcualteBiasPercent = (currentPrice: number, low: number, hight: number) => {
        let bias = 0;
        const nearest = currentPrice < low ? low : currentPrice > hight ? hight : currentPrice;

        if (currentPrice < low) {
            bias = Math.round((1 - currentPrice / low) * 100);
        } else if (currentPrice > hight) {
            bias = Math.round((1 - hight / currentPrice) * 100);
        }

        setBias({
            percent: bias,
            isNegative: currentPrice < nearest
        });
    };

    useEffect(() => {
        calcualteBiasPercent(currentPrice, low, hight);
    }, [currentPrice, low, hight]);

    const displayBias = bias.percent !== 0;
    return (
        <>
            {!!data && !error && (
                <div
                    className={`${classes.ProductChartLine} ${
                        displayBias ? classes.BiasDisplayed : ""
                    }`}
                >
                    <div
                        className={classes.Col}
                        style={
                            !displayBias
                                ? {
                                      maxWidth: 165
                                  }
                                : {}
                        }
                    >
                        <h3>{renderPrice(offer.price)}</h3>
                        {displayBias && (
                            <div>
                                <span>
                                    {bias.isNegative ? "Ниже" : "Выше"} оценки на {bias.percent}%
                                </span>
                            </div>
                        )}
                    </div>
                    <div className={`${classes.Col} ${classes.SecondCol}`}>
                        <div className={classes.HeaderTexts}>
                            {" "}
                            <span>Ниже оценки</span> <span>Выше оценки</span>
                        </div>
                        <div className={classes.ProgressBar}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="13"
                                viewBox="0 0 14 13"
                                fill="none"
                                className={classes.Cursor}
                                style={{
                                    // left: `${leftPercent > 90 ? 90 : (leftPercent >= 10 ? leftPercent : 10)}%`,
                                    left: `${leftPercent > 90 ? 90 : leftPercent >= 10 ? leftPercent : 10}%`
                                }}
                            >
                                <path
                                    d="M8.73205 11C7.96225 12.3333 6.03775 12.3333 5.26795 11L0.937822 3.5C0.168022 2.16667 1.13027 0.5 2.66987 0.5H11.3301C12.8697 0.5 13.832 2.16667 13.0622 3.5L8.73205 11Z"
                                    fill="#1D1D1B"
                                    stroke="white"
                                />
                            </svg>
                            <div className={classes.BGLine}></div>
                            <div className={classes.GreenLine}></div>

                            <div className={classes.SeparatorFrom}></div>
                            <div className={classes.SeparatorTo}></div>
                            <div className={classes.TextFrom}>от {numberWithSpaces(low)} ₽</div>
                            <div className={classes.TextTo}>до {numberWithSpaces(hight)} ₽</div>
                        </div>
                    </div>
                    <div className={classes.Col}>
                        <p>
                            У каждого авто может быть различная комплектация, пробег и иные
                            характеристики
                        </p>
                    </div>
                </div>
            )}
        </>
    );
};
export default ProductChartLine;