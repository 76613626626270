import React from "react";
import SvgInterface from "@shared/lib/interfaces/SvgInterface";
import classes from "./svg.module.scss";
export interface FavoriteIconProps extends SvgInterface {
    isSelected?: boolean;
}

/* eslint-disable react/display-name */
export default React.memo(
    ({ width, height, color, isSelected = false, ...other }: FavoriteIconProps) => {
        return (
            <svg
                className={`${classes.HeartBtn} ${isSelected ? classes.Selected : undefined}`}
                width="20"
                height="18"
                viewBox="0 0 20 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    className={classes.HeartActive}
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.208 1.70016C17.0921 0.584641 15.5947 -0.0273122 14.0433 0.000936316C12.479 0.00172282 11.0566 1.01121 10.0192 2.14075C8.97104 1.01068 7.53 0.00171796 5.9567 0.000936301C4.40524 -0.0273121 2.9078 0.584641 1.79196 1.70016C0.675942 2.81584 0.0324006 4.34417 0 5.94973V5.95367C0 7.55907 0.628395 9.00477 1.64795 10.0751L1.92311 10.3398L9.33204 17.7085C9.70783 18.0972 10.3323 18.0972 10.708 17.7083L18.0863 10.3301C18.6861 9.78337 19.1667 9.11074 19.4965 8.35715C19.8268 7.60213 19.9985 6.7829 20 5.95403L19.9999 5.94973C19.9675 4.34417 19.324 2.81584 18.208 1.70016ZM18.0535 5.95543C18.053 7.12915 17.5404 8.19541 16.732 8.93666L10.0196 15.6382L3.28365 8.94206L3.06383 8.73438C2.71128 8.37168 2.43078 7.93968 2.23886 7.46302C2.04666 6.98568 1.94722 6.47324 1.94643 5.95536C1.95658 5.41765 2.06843 4.88746 2.27545 4.39507C2.4827 3.9021 2.78116 3.45691 3.15341 3.08471C3.52564 2.71253 3.96434 2.42064 4.44425 2.22531C4.92413 2.02999 5.43607 1.93497 5.95089 1.94541H5.95487C6.60634 1.94541 7.22934 2.24412 7.79479 2.69018C8.35921 3.13543 8.85017 3.71501 9.2331 4.24561C9.41968 4.50416 9.70799 4.64762 10.0189 4.64792C10.1731 4.64929 10.3252 4.61364 10.4633 4.54414C10.6016 4.47451 10.7218 4.37299 10.8149 4.24809L10.8174 4.24464C11.196 3.71319 11.6747 3.13389 12.2278 2.68902C12.7816 2.24362 13.3942 1.94545 14.0451 1.94545L14.0491 1.94537C14.5639 1.93493 15.0758 2.02999 15.5557 2.22531C16.0356 2.42064 16.4743 2.71253 16.8465 3.08471C17.2188 3.45691 17.5173 3.9021 17.7245 4.39507C17.9315 4.88749 18.0434 5.4177 18.0535 5.95543Z"
                    fill="#7B869B"
                />
                <path
                    className={classes.HeartNotActive}
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.208 1.70016C17.0921 0.58464 15.5947 -0.0273122 14.0433 0.000936316C12.479 0.00172282 11.0566 1.01121 10.0192 2.14075C8.97104 1.01068 7.53 0.00171796 5.9567 0.000936301C4.40524 -0.0273121 2.9078 0.58464 1.79196 1.70016C0.675942 2.81584 0.0324006 4.34417 0 5.94973V5.95367C0 7.55907 0.628395 9.00477 1.64795 10.0751L1.92311 10.3398L9.33204 17.7085C9.70783 18.0972 10.3323 18.0972 10.708 17.7083L18.0863 10.3301C18.6861 9.78337 19.1667 9.11074 19.4965 8.35715C19.8268 7.60213 19.9985 6.7829 20 5.95403L19.9999 5.94973C19.9675 4.34417 19.324 2.81584 18.208 1.70016Z"
                    fill="#DA3832"
                />
            </svg>
        );
    }
);