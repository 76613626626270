import React, { createElement, FunctionComponent, ReactElement, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import useOnClickOutside from "@src/hooks/userOutsideHook";
import { setIsSoonVisible } from "../../../app/store/layoutSlice";
import AutoIcon from "@shared/ui/Icon/ui/AutoIcon";
import JobIcon from "../../../components/Svg/JobIcon";
import MarketsIcon from "../../../components/Svg/MarketsIcon";
import PropertyIcon from "../../../components/Svg/PropertyIcon";
import MenuButton from "../../Header/MenuButton";
import Items, { MenuItem } from "../MenuItems";
import classes from "./MobileMenu.module.scss";
import InsuranceIcon from "../../../components/Svg/InsuranceIcon";
import HomeIcon from "../../../components/Svg/HomeIcon";
import EstimateIcon from "../../../components/Svg/MenuIcons/EstimateIcon";

export interface MobileMenuProps {
    activeItem: MenuItem;
    className?: string;
}

const MobileMenu = ({ activeItem, className = "" }: MobileMenuProps) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const mobileItems = [
        {
            title: "Главная",
            icon: AutoIcon,
            link: "/"
        },
        ...Items
    ];
    useOnClickOutside(ref, (event: any) => {
        const isTargetButton = event?.path?.some((el: HTMLElement) => {
            if (el.getAttribute) {
                const id = el.getAttribute("id");
                return id === "mobile-menu";
            } else {
                return false;
            }
        });

        const isTargetButton2 = event?.composedPath()?.some((el: HTMLElement) => {
            if (el.getAttribute) {
                const id = el.getAttribute("id");
                return id === "mobile-menu";
            } else {
                return false;
            }
        });

        if (!isTargetButton && !isTargetButton2) {
            setIsExpanded(false);
        }
    });

    const Icon = activeItem.icon;
    return (
        <div id="mobile-menu" className={`${classes.MobileMenu} ${className}`}>
            <span
                onClick={() => {
                    setIsExpanded(!isExpanded);
                }}
            >
                <div className={classes.ButtonContainer}>
                    <Icon color="#3B85F7" />
                    <span>{activeItem.title}</span>
                </div>
                <div className={classes.Icon}>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M6.90944 9.77806C6.59702 10.0905 6.59702 10.597 6.90944 10.9094L11.4349 15.4349C11.7473 15.7473 12.2539 15.7473 12.5663 15.4349L17.0918 10.9094C17.4042 10.597 17.4042 10.0905 17.0918 9.77806C16.7794 9.46565 16.2728 9.46565 15.9604 9.77806L12.0006 13.7379L8.04081 9.77806C7.72839 9.46565 7.22185 9.46565 6.90944 9.77806Z"
                            fill="#3B85F7"
                        />
                    </svg>
                </div>
            </span>

            {isExpanded && (
                <div ref={ref} className={classes.ExpandedMenuCont}>
                    <div className={classes.ExpandedMenu}>
                        <div className={classes.Li}>
                            <MenuButton
                                title={"Главная"}
                                icon={createElement(HomeIcon)}
                                link={"/"}
                            />
                        </div>
                        <div
                            style={{
                                paddingLeft: "21px"
                            }}
                            className={classes.Li}
                        >
                            <MenuButton
                                title={"Авто"}
                                icon={createElement(AutoIcon)}
                                link={"/catalog"}
                            />
                        </div>
                        <div
                            style={{
                                paddingLeft: "26px"
                            }}
                            className={classes.Li}
                        >
                            <MenuButton
                                title={"Страхование"}
                                icon={createElement(InsuranceIcon)}
                                link={"/insurance"}
                                labelStyle={{
                                    paddingLeft: 11
                                }}
                            />
                        </div>
                        {/* TODO: Временно скрываем страницу оценки авто.
                            Для подробностей см. задачу LIKVI-1007 в Jira. */}
                        {/*
                        <div
                            style={{
                                paddingLeft: "24px",
                            }}
                            className={classes.Li}
                        >

                            <MenuButton
                                title={"Оценка авто"}
                                icon={createElement(EstimateIcon)}

                                link={"/analytics"}


                                labelStyle={{
                                    paddingLeft: 6
                                }}

                            />
                        </div>*/}
                        <div
                            className={classes.Disabled}
                            onClick={(e) => {
                                e.preventDefault();
                                dispatch(setIsSoonVisible(true));
                            }}
                        >
                            <div className={classes.Soon}>Скоро</div>

                            <div className={classes.Li}>
                                <MenuButton
                                    disabled={true}
                                    title={"Маркетплейсы"}
                                    icon={createElement(MarketsIcon)}
                                    link={"/marketplaces"}
                                />
                            </div>
                            <div className={classes.Li}>
                                <MenuButton
                                    disabled={true}
                                    title={"Работа"}
                                    icon={createElement(JobIcon)}
                                    link={"/job"}
                                />
                            </div>
                            <div className={classes.Li}>
                                <MenuButton
                                    disabled={true}
                                    title={"Недвижимость"}
                                    icon={createElement(PropertyIcon)}
                                    link={"/"}
                                />
                            </div>
                        </div>
                        {/* {mobileItems.map((item) => {
            return <li key={item.title}>
              <MenuButton
                title={item.title}
                icon={createElement(item.icon)}
                link={item.link}
              />
            </li>
          })} */}
                    </div>
                </div>
            )}
        </div>
    );
};
export default MobileMenu;