import React from "react";
import SvgInterface from "@shared/lib/interfaces/SvgInterface";

export default React.memo(function CancelMaterialIcon({
    width,
    height,
    color,
    ...other
}: SvgInterface) {
    return (
        <svg
            onClick={other.onClick}
            style={other.style}
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="16" cy="16" r="16" fill="#F8F8F8" />
            <path
                d="M20.78 12.2822C21.0733 11.9889 21.0733 11.5133 20.78 11.22C20.4867 10.9267 20.0111 10.9267 19.7178 11.22L16 14.9378L12.2822 11.22C11.9889 10.9267 11.5133 10.9267 11.22 11.22C10.9267 11.5133 10.9267 11.9889 11.22 12.2822L14.9378 16L11.22 19.7178C10.9267 20.0111 10.9267 20.4867 11.22 20.78C11.5133 21.0733 11.9889 21.0733 12.2822 20.78L16 17.0622L19.7178 20.78C20.0111 21.0733 20.4867 21.0733 20.78 20.78C21.0733 20.4867 21.0733 20.0111 20.78 19.7178L17.0622 16L20.78 12.2822Z"
                fill="#343B47"
            />
        </svg>
    );
});