import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { currentUserState } from "@app/store/authSlice";
import { showEnterForm } from "../../app/store/layoutSlice";
import EnterIcon from "../../components/Svg/EnterIcon";
import EyeBtnIcon from "../../components/Svg/EyeBtnIcon";
import FavoriteBtnIcon from "../../components/Svg/FavoriteBtnIcon";
import JobIcon from "../../components/Svg/JobIcon";
import PlusIcon from "../../components/Svg/PlusIcon";
import ProfileIcon from "../../components/Svg/ProfileIcon";
import PropertyIcon from "../../components/Svg/PropertyIcon";
import Logo from "../../components/UI/Logo/Logo";
import DesktopMenu from "../Menu/DesktopMenu/DesktopMenu";
import Items from "../Menu/MenuItems";
import MobileMenu from "../Menu/MobileMenu/MobileMenu";
import UserMenu from "../UserMenu/UserMenu";
import classes from "./Header.module.scss";
import MenuButton from "./MenuButton";

interface HeaderProps {}

const Header = (props: HeaderProps) => {
    const router = useRouter();
    const [hasNotifications, setHasNotifications] = useState(false);
    const [isUserMenuOpened, setIsUserMenuOpened] = useState(false);

    const currentUser = useSelector(currentUserState);
    const dispatch = useDispatch();
    useEffect(() => {
        setTimeout(() => {
            setHasNotifications(true);
        }, 1000);

        setTimeout(() => {
            setHasNotifications(false);
        }, 1500);

        setTimeout(() => {
            setHasNotifications(true);
        }, 2000);
    }, []);

    let activeItem = Items.find((el) => {
        return el.link === router.pathname;
    });

    activeItem = activeItem
        ? activeItem
        : {
              title: "Главная",
              icon: PropertyIcon,
              link: "/"
          };

    return (
        <div className={classes.Header}>
            <div className={classes.Container}>
                <div className={classes.LeftPart}>
                    <Link legacyBehavior href={"/"}>
                        <a className={classes.Logo} aria-label="LIKVI.COM">
                            <Logo />
                        </a>
                    </Link>
                    <DesktopMenu activeItem={activeItem} className={classes.DesktopMenu} />
                    <MobileMenu activeItem={activeItem} className={classes.MobileMenu} />
                </div>

                <div className={classes.RightPart}>
                    <div className={classes.UserMenu}>
                        {!currentUser && (
                            <MenuButton
                                id={classes["EnterButton"]}
                                onClick={() => dispatch(showEnterForm())}
                                title="Войти"
                                icon={<EnterIcon />}
                            />
                        )}
                        {currentUser && (
                            <div>
                                <MenuButton
                                    id={"user-menu-button"}
                                    className={classes.UserMenuButton}
                                    onClick={() => {
                                        setIsUserMenuOpened(!isUserMenuOpened);
                                    }}
                                    title={currentUser.name}
                                    icon={<ProfileIcon />}
                                    spanStyle={{
                                        maxWidth: 86,
                                        overflow: "hidden",
                                        textOverflow: "ellipsis"
                                    }}
                                />
                                {isUserMenuOpened && (
                                    <UserMenu
                                        close={() => {
                                            setIsUserMenuOpened(false);
                                        }}
                                    />
                                )}
                            </div>
                        )}
                        <div className={classes.Divider}></div>
                        <ul>
                            <li>
                                <MenuButton
                                    link={"/saved"}
                                    icon={<FavoriteBtnIcon hasNotifications={false} />}
                                    ariaLabel={"Избранное"}
                                />
                            </li>
                            {/* <li>
                            <MenuButton onClick={
                                () => {
                                    router.push("/history");
                                }
                            }
                                icon={<EyeBtnIcon />}
                            />
                        </li> */}
                        </ul>

                        <button
                            className={classes.PrimaryButton}
                            aria-label="Создать объявление"
                            onClick={() => {
                                router.push("/post");
                            }}
                        >
                            <PlusIcon />
                            <span>Создать объявление</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;