import React, { FC } from "react";

export const TelegramIcon: FC<{ className?: string }> = ({ className }) => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3909_566)">
            <path
                d="M13.006 25.7379C20.038 25.7379 25.7386 20.0373 25.7386 13.0053C25.7386 5.97327 20.038 0.272705 13.006 0.272705C5.974 0.272705 0.273438 5.97327 0.273438 13.0053C0.273438 20.0373 5.974 25.7379 13.006 25.7379Z"
                fill="url(#paint0_linear_3909_566)"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.02867 12.8674C9.73924 11.256 12.2094 10.1852 13.4498 9.66573C16.9802 8.1921 17.7223 7.93766 18.1994 7.92706C18.3054 7.92706 18.5386 7.94827 18.6976 8.07549C18.8248 8.1815 18.8567 8.31932 18.8779 8.42534C18.8991 8.53136 18.9203 8.75399 18.8991 8.92362C18.7082 10.9379 17.8813 15.8253 17.4572 18.0728C17.277 19.027 16.9272 19.345 16.5879 19.3768C15.8458 19.4404 15.2839 18.8892 14.5736 18.4227C13.4498 17.6912 12.8243 17.2353 11.7323 16.5144C10.4708 15.6875 11.2871 15.2316 12.008 14.4895C12.1988 14.2987 15.4535 11.3302 15.5171 11.0651C15.5277 11.0333 15.5277 10.9061 15.4535 10.8425C15.3793 10.7789 15.2733 10.8001 15.1885 10.8213C15.0719 10.8425 13.2908 12.0299 9.82405 14.3729C9.31518 14.7227 8.8593 14.8923 8.44584 14.8817C7.98997 14.8711 7.12064 14.6273 6.46333 14.4153C5.66821 14.1608 5.03211 14.023 5.08512 13.5777C5.11693 13.3445 5.43497 13.1113 6.02867 12.8674Z"
                fill="white"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_3909_566"
                x1="12.9954"
                y1="0.272705"
                x2="12.9954"
                y2="25.5364"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#2AABEE" />
                <stop offset="1" stop-color="#229ED9" />
            </linearGradient>
            <clipPath id="clip0_3909_566">
                <rect
                    width="25.4545"
                    height="25.4545"
                    fill="white"
                    transform="translate(0.273438 0.272705)"
                />
            </clipPath>
        </defs>
    </svg>
);