import React, { useEffect, useState } from "react";
import classes from "./FinishRegForm.module.scss";
import { useForm, Controller } from "react-hook-form";
import TextInput from "../../../UI/Inputs/TextInput/TextInput";
import { PrimaryButton } from "../../../UI/Buttons/PrimaryButton/PrimaryButton";
import { useSelector, useDispatch } from "react-redux";
import { hideModals, showEnterForm, showRegistrationForm } from "../../../../app/store/layoutSlice";
import PhoneInput from "react-phone-input-2";
import { isValidEmail } from "../../../../utils/utils";
import { clearErrors, registerUser } from "../../../../app/store/authSlice";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { AppState } from "../../../../app/store/store";
import { useUpdateProfileMutation } from "../../../../app/store/profileApi";

export interface FinishRegFormProps {
    onClose: () => void;
}

const FinishRegForm = ({ onClose, ...props }: FinishRegFormProps) => {
    const dispatch = useDispatch<ThunkDispatch<AppState, any, any>>();
    const [updateProfile, response] = useUpdateProfileMutation();
    // reset back errors
    useEffect(() => {
        dispatch(clearErrors());
    }, []);

    const [isLoading, setIsLoading] = useState(false);
    const errorMessage = useSelector((state: AppState) => state.auth.errorMessage);
    const userData = useSelector((state: AppState) => state.auth.currentUser);
    const [isErrorString, setIsErrorSting] = useState(typeof errorMessage === "string");
    const showError = (errName: string) => {
        const errMsg = errors[errName]?.message;
        return <span className={classes.Error}>{errMsg && errMsg.toString()}</span>;
    };

    const textInputStyle = {
        paddingLeft: 10
    };

    const {
        handleSubmit,
        formState: { errors },
        control,
        watch,
        setError
    } = useForm();

    useEffect(() => {
        const isErrorString = typeof errorMessage === "string";
        setIsErrorSting(isErrorString);
        if (!isErrorString && !!errorMessage) {
            setError("RegPassword", {
                message: errorMessage?.password
            });
        }
    }, [errorMessage]);

    const onSubmit = () => {
        const submit = handleSubmit(async (data) => {
            setIsLoading(true);
            const body: any = {
                firstName: data.RegName
            };
            if (!userData?.phone) {
                body.phone = data.RegPhone;
            }

            if (!userData?.email) {
                body.email = data.RegEmail;
            }

            await updateProfile(body);
            // const res = await dispatch(
            //     registerUser({
            //         email: data.RegEmail,
            //         password: data.RegPassword,
            //         phone: data.RegPhone,
            //     })
            // );
            dispatch(hideModals());
        });

        setIsLoading(false);
        submit();
    };

    const handleEmailValidation = (email: string) => {
        if (!email) {
            return true;
        }
        if (email.replace(" ", "") === "") {
            return true;
        }
        const isValid = isValidEmail(email);
        return isValid ? isValid : "Неверный формат почты";
    };

    return (
        <div
            onKeyDown={(event) => {
                if (event.key === "Enter") {
                    onSubmit();
                }
            }}
            className={classes.FinishRegForm}
        >
            <div onClick={onClose} className={classes.CloseBtn}>
                <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M13.692 1.79511C14.1027 1.38445 14.1027 0.718653 13.692 0.307993C13.2814 -0.102656 12.6155 -0.102661 12.2049 0.307993L7 5.51289L1.79511 0.307993C1.38445 -0.102667 0.718647 -0.102661 0.307993 0.307993C-0.102661 0.718647 -0.102667 1.38445 0.307993 1.79511L5.51289 7L0.307993 12.2049C-0.102661 12.6155 -0.102667 13.2813 0.307993 13.692C0.718653 14.1027 1.38445 14.1027 1.79511 13.692L7 8.48711L12.2049 13.692C12.6156 14.1027 13.2814 14.1027 13.692 13.692C14.1027 13.2814 14.1027 12.6156 13.692 12.2049L8.48711 7L13.692 1.79511Z"
                        fill="#F86A6A"
                    />
                </svg>
            </div>
            <h3>Завершение</h3>
            <form onSubmit={onSubmit}>
                <div className={`${classes.InputCont} ${classes.InputContRequired}`}>
                    <label>Имя</label>
                    <Controller
                        control={control}
                        name="RegName"
                        rules={{
                            required: 'Заполните поле "Имя"',
                            minLength: {
                                value: 3,
                                message: "Минимальная длина имени 3 символа"
                            }
                        }}
                        render={({ field }) => {
                            return (
                                <TextInput
                                    value={field.value}
                                    onChange={field.onChange}
                                    style={textInputStyle}
                                    placeholder="Введите имя"
                                />
                            );
                        }}
                    />
                    {errors.RegName && showError("RegName")}
                </div>

                {!userData?.email && (
                    <div className={classes.InputCont}>
                        <label>Электронная почта</label>
                        <Controller
                            control={control}
                            name="RegEmail"
                            rules={{
                                // required: 'Заполните поле "Электронная почта"',
                                validate: handleEmailValidation
                            }}
                            // defaultValue="testName"
                            render={({ field }) => {
                                return (
                                    <TextInput
                                        value={field.value}
                                        onChange={field.onChange}
                                        style={textInputStyle}
                                        placeholder="Введите электронную почту"
                                    />
                                );
                            }}
                        />
                        {errors.RegEmail && showError("RegEmail")}
                    </div>
                )}

                {!userData?.phone && (
                    <div className={classes.InputCont}>
                        <label>Телефон</label>
                        <Controller
                            control={control}
                            name="RegPhone"
                            rules={{
                                // required: 'Заполните поле "Телефон"',
                                minLength: {
                                    value: 11,
                                    message: "Номер телефона должен содержать 11 цифр"
                                }
                            }}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <PhoneInput
                                    country={"ru"}
                                    value={value}
                                    onChange={(phone) => onChange(phone)}
                                />
                            )}
                        />
                        {errors.RegPhone && showError("RegPhone")}
                    </div>
                )}

                {isErrorString && errorMessage && (
                    <span className={classes.Error}>{errorMessage.slice(0, 140) + "..."}</span>
                )}
            </form>

            <PrimaryButton
                style={{
                    marginTop: 30,
                    marginBottom: 18,
                    height: 56,
                    fontSize: 18
                }}
                isLoading={isLoading}
                onClick={(e) => {
                    e.preventDefault();
                    onSubmit();
                }}
                content="Сохранить"
            />
        </div>
    );
};
export default FinishRegForm;