import { FunctionComponent, ReactElement } from "react";
import SvgInterface from "@shared/lib/interfaces/SvgInterface";
import AutoIcon from "@shared/ui/Icon/ui/AutoIcon";
import JobIcon from "../../components/Svg/JobIcon";
import MarketsIcon from "../../components/Svg/MarketsIcon";
import PropertyIcon from "../../components/Svg/PropertyIcon";
import InsuranceIcon from "../../components/Svg/InsuranceIcon";
import EstimateIcon from "../../components/Svg/MenuIcons/EstimateIcon";
export interface MenuItem {
    title: string;
    icon: any;
    link: string;
}

const Items: MenuItem[] = [
    {
        title: "Авто",
        icon: AutoIcon,
        link: "/catalog"
    },
    {
        title: "Работа",
        icon: JobIcon,
        link: "/job"
    },
    {
        title: "Страхование",
        icon: InsuranceIcon,
        link: "/insurance"
    },
    {
        title: "Оценка авто",
        icon: EstimateIcon,
        link: "/analycs/estimate"
    },

    {
        title: "Недвижимость",
        icon: PropertyIcon,
        link: "/property"
    },
    {
        title: "Маркетплейсы",
        icon: MarketsIcon,
        link: "/marketplaces"
    }
];

export default Items;