import React from "react";
import SvgInterface from "@shared/lib/interfaces/SvgInterface";

export default React.memo(function Logo({ width, height, color, ...other }: SvgInterface) {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.1086 8.43153C14.3022 8.23786 14.5647 8.12914 14.8385 8.12912C15.1123 8.12912 15.3747 8.23785 15.5684 8.43153C15.7619 8.62503 15.8706 8.88768 15.8706 9.16129C15.8706 10.4521 15.1821 11.6448 14.0643 12.2902C12.9464 12.9355 11.5694 12.9355 10.4515 12.2902C9.33367 11.6449 8.64516 10.4521 8.64516 9.16129C8.64516 8.79263 8.8419 8.4519 9.16123 8.26739C9.48054 8.08306 9.87404 8.08306 10.1934 8.26739C10.5127 8.45186 10.7094 8.79263 10.7094 9.16129C10.7094 9.71445 11.0046 10.2257 11.4837 10.5024C11.9629 10.7789 12.553 10.7789 13.0321 10.5024C13.5111 10.2257 13.8063 9.71445 13.8063 9.16129C13.8063 8.88767 13.9151 8.62504 14.1086 8.43153Z"
                fill="#818BA1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.6797 7.03744L20.4978 15.6336H20.4977C20.5101 15.7589 20.516 15.8845 20.5157 16.0103C20.5149 17.0682 20.0944 18.0826 19.3464 18.8307C18.5984 19.5788 17.5841 19.9993 16.5262 20H7.98951C6.93161 19.9993 5.91725 19.5788 5.16934 18.8307C4.42123 18.0826 4.00074 17.0682 4.00002 16.0103C3.99966 15.8845 4.0056 15.7589 4.01802 15.6336L4.83615 7.03744C4.915 6.20654 5.30094 5.43481 5.91853 4.87302C6.53598 4.31141 7.34061 4.00018 8.1753 4H16.3405C17.1753 4.00018 17.9799 4.31138 18.5973 4.87302C19.2149 5.43485 19.6008 6.20654 19.6797 7.03744ZM7.98963 17.9355H16.5265C17.037 17.9355 17.5266 17.7326 17.8877 17.3715C18.2488 17.0106 18.4515 16.521 18.4515 16.0103V15.8271L17.631 7.23372C17.6006 6.91295 17.4513 6.61523 17.2127 6.39886C16.974 6.18249 16.6627 6.06332 16.3407 6.06476H8.17544C7.85342 6.06333 7.54217 6.18249 7.30348 6.39886C7.06479 6.61521 6.91557 6.91295 6.88514 7.23372H6.88533L6.06465 15.8271V16.0103C6.06465 16.521 6.26733 17.0106 6.62842 17.3715C6.98952 17.7326 7.47914 17.9355 7.98963 17.9355Z"
                fill="#818BA1"
            />
        </svg>
    );
});