import React from "react";
import SvgInterface from "@shared/lib/interfaces/SvgInterface";

/* eslint-disable react/display-name */
export default React.memo(function AutoIcon({ width, height, color, ...other }: SvgInterface) {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.33594 12.0616C9.0532 12.0616 8.7818 12.1741 8.58185 12.3741C8.3817 12.574 8.26935 12.8454 8.26935 13.1284C8.26935 13.4113 8.3817 13.6825 8.58185 13.8826C8.78181 14.0826 9.0532 14.1949 9.33594 14.1949C9.61886 14.1949 9.89027 14.0826 10.0902 13.8826C10.2904 13.6825 10.4027 13.4113 10.4027 13.1284C10.4027 12.8454 10.2904 12.574 10.0902 12.3741C9.89026 12.1741 9.61887 12.0616 9.33594 12.0616Z"
                fill="#3B85F7"
            />
            <path
                d="M15.2026 12.0616C14.9196 12.0616 14.6482 12.1741 14.4483 12.3741C14.2481 12.574 14.1358 12.8454 14.1358 13.1284C14.1358 13.4113 14.2481 13.6825 14.4483 13.8826C14.6482 14.0826 14.9196 14.1949 15.2026 14.1949C15.4853 14.1949 15.7567 14.0826 15.9566 13.8826C16.1568 13.6825 16.2691 13.4113 16.2691 13.1284C16.2691 12.8454 16.1568 12.574 15.9566 12.3741C15.7567 12.1741 15.4853 12.0616 15.2026 12.0616Z"
                fill="#3B85F7"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.8516 5.0104C17.5393 5.45254 18.0867 6.08163 18.4292 6.82401L18.4293 6.8241C19.9877 10.2145 20.7972 13.9009 20.8026 17.6321C20.8019 18.3475 20.5173 19.0331 20.0113 19.5387C19.5052 20.0441 18.8192 20.3281 18.1039 20.3281H16.864C16.3159 20.3259 15.7814 20.1575 15.3311 19.8452C14.8806 19.5329 14.5356 19.0913 14.3414 18.5789V18.5787L14.1974 18.1947H10.3414L10.1974 18.5789C10.003 19.0917 9.65759 19.5336 9.2067 19.8459C8.75561 20.1583 8.22066 20.3264 7.6721 20.3281H6.43196C5.7173 20.3273 5.03183 20.0431 4.52646 19.5377C4.02105 19.0321 3.73683 18.3469 3.73608 17.632C3.74166 13.9006 4.55117 10.2144 6.1094 6.82401C6.45182 6.08163 6.99929 5.45255 7.68695 5.0104C8.3748 4.56827 9.17446 4.33147 9.99198 4.32812H14.5466C15.3641 4.33146 16.1638 4.56826 16.8516 5.0104ZM16.864 18.195H18.1069C18.4175 18.195 18.6694 17.9431 18.6694 17.6323C18.6699 14.209 17.9276 10.8262 16.4934 7.71762C16.323 7.3441 16.0492 7.02714 15.7042 6.80448C15.3591 6.58183 14.9573 6.46278 14.5468 6.46167H9.99219C9.58167 6.46278 9.17986 6.58182 8.83482 6.80448C8.48978 7.02715 8.21598 7.34411 8.04559 7.71762C6.61165 10.8262 5.86929 14.2089 5.86966 17.6323C5.86966 17.9431 6.1215 18.195 6.43213 18.195H7.66426C7.90123 18.1977 8.1144 18.0515 8.19754 17.8296L8.53627 16.9256C8.63188 16.6713 8.80282 16.4524 9.02641 16.298C9.24984 16.1436 9.51526 16.061 9.78683 16.0616H14.752C15.0236 16.061 15.289 16.1436 15.5124 16.298C15.736 16.4524 15.907 16.6714 16.0026 16.9256L16.3307 17.8296C16.4137 18.0515 16.627 18.1977 16.864 18.195Z"
                fill={color || "black"}
            />
        </svg>
    );
});