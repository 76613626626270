export const DOCUMENTS = {
    LABEL: "Документы:",
    PRIVACY_POLICY_TEXT: "Политика конфиденциальности",
    TERMS_OF_USE_TEXT: "Правила пользования сайтом"
};

export const CONTACTS = {
    LABEL: "Контакты:",
    SUPPORT: {
        TEXT: "Поддержка",
        ADDRESS: "support@likvi.com"
    },
    COMMERCE: {
        TEXT: "Для партнеров",
        ADDRESS: "commerce@likvi.com"
    }
};

export const COPYRIGHT_NOTICE = "(c) Likvi | Все права защищены";