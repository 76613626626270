import React from "react";
import classes from "./Modals.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../app/store/store";
import { Modal } from "react-responsive-modal";
import EnterForm from "../Auth/EnterForm/EnterForm";
import RegForm from "../Auth/RegForm/RegForm";
import RestorePassForm from "../Auth/RestorePassForm/RestorePassForm";
import { isPrivateRoute } from "../Auth/AuthCheck";
import { usePathname, useRouter } from "next/navigation";
import EnterByPhoneForm from "../Auth/EnterByPhoneForm/EnterByPhoneForm";
import EnterByEmailForm from "../Auth/EnterByEmailForm/EnterByEmailForm";
import FinishRegistrationForm from "../Auth/FinishRegistrationForm/FinishRegistrationForm";
import FinishRegForm from "../Auth/FinishRegForm/FinishRegForm";
import EnterCodeForm from "../Auth/EnterCodeForm/EnterCodeForm";
import CallbackForm from "@features/callback-form/callback-form";
import { hideModals } from "@app/store/layoutSlice";

export interface ModalsProps {}

const Modals = ({}: ModalsProps) => {
    const dispatch = useDispatch();

    const enterFormVisible = useSelector((state: AppState) => state.layout.enterFormVisible);
    const isSoonVisible = useSelector((state: AppState) => state.layout.isSoonVisible);
    const regFormVisible = useSelector((state: AppState) => state.layout.registrationFormVisible);
    const {
        enterByEmailVisible,
        emailEnterForm,
        finishRegistrationFormVisible,
        enterCodeFormVisible,
        callbackFormVisible
    } = useSelector((state: AppState) => state.layout);
    const restorePassFormVisible = useSelector(
        (state: AppState) => state.layout.restorePassFormVisible
    );
    const modalVisible =
        enterFormVisible ||
        regFormVisible ||
        restorePassFormVisible ||
        enterByEmailVisible ||
        emailEnterForm ||
        finishRegistrationFormVisible ||
        enterCodeFormVisible ||
        callbackFormVisible;

    const user = useSelector((state: AppState) => state.auth.currentUser);

    const pathname = usePathname();
    const isPrivate = isPrivateRoute(pathname || "");
    const router = useRouter();

    const onCloseModal = () => {
        if (isPrivate) {
            if (user) {
                dispatch(hideModals());
            } else {
                router.push("/");
                setTimeout(() => {
                    dispatch(hideModals());
                }, 500);
            }
        } else {
            dispatch(hideModals());
        }
    };

    return (
        <div className={classes.Modals}>
            {modalVisible && (
                <Modal
                    styles={{
                        overlay: {
                            backdropFilter: isPrivate ? "blur(5px)" : undefined,
                            // @ts-ignore
                            webKitBackdropFilter: isPrivate ? "blur(5px)" : undefined
                        },
                        modal: {
                            padding: 0,
                            margin: 0,
                            background: "transparent",
                            width: "100%",
                            maxWidth: 400
                        }
                    }}
                    open={modalVisible}
                    onClose={onCloseModal}
                    center
                    showCloseIcon={false}
                >
                    {enterByEmailVisible && <EnterByEmailForm onClose={onCloseModal} />}
                    {enterFormVisible && <EnterByPhoneForm onClose={onCloseModal} />}
                    {regFormVisible && <RegForm onClose={onCloseModal} />}
                    {restorePassFormVisible && <RestorePassForm onClose={onCloseModal} />}
                    {emailEnterForm && <EnterForm onClose={onCloseModal} />}
                    {finishRegistrationFormVisible && <FinishRegForm onClose={onCloseModal} />}
                    {enterCodeFormVisible && <EnterCodeForm onClose={onCloseModal} />}
                    {callbackFormVisible && <CallbackForm onClose={onCloseModal} />}
                </Modal>
            )}
        </div>
    );
};
export default Modals;