import React from "react";
import { PrimaryButton } from "../PrimaryButton/PrimaryButton";
import classes from "./ContactButton.module.scss";
import { motion } from "framer-motion";
import { formatToPhone } from "../../../../utils/utils";
export interface ContactButtonProps {
    phone?: string;
    name?: string;
}

const ContactButton = ({ phone, name }: ContactButtonProps) => {
    const formatedPhone = phone ? formatToPhone(phone) : "Номер неизвестен";
    let i = 5;
    const maskedPhone = phone ? formatedPhone.slice(0, -7) + "X-XX-XX" : "Номер неизвестен";
    const formattedName = name ?? "";
    const phoneRef = React.useRef<HTMLAnchorElement>(null);
    const [isPhoneLoading, setIsPhoneLoading] = React.useState(false);
    const [isPhoneVisible, setIsPhoneVisible] = React.useState(false);

    const phoneButtonVariants = {
        hidden: {
            display: "none",
            opacity: 0,
            scale: 0
        },
        visible: {
            display: "block",
            opacity: 1,
            scale: 1,
            transition: {
                // delay: .2,
                duration: 0.2
            }
        }
    };

    return (
        <div className={classes.ContactButton}>
            <PrimaryButton
                type="Primary"
                isLoading={isPhoneLoading}
                onClick={() => {
                    if (isPhoneVisible) {
                        if (phoneRef.current) {
                            phoneRef.current?.click();
                        }

                        return;
                    }
                    setIsPhoneLoading(true);
                    setTimeout(() => {
                        setIsPhoneVisible(!isPhoneVisible);
                        setIsPhoneLoading(false);
                    }, 1000);
                }}
                content={
                    <div style={{ height: 32, display: "flex", alignItems: "center" }}>
                        <motion.div
                            initial="hidden"
                            variants={phoneButtonVariants}
                            animate={isPhoneVisible ? "visible" : "hidden"}
                            transition={{
                                duration: 0.2
                            }}
                        >
                            <div>
                                <a ref={phoneRef} href={"tel:" + formatedPhone}>
                                    {formatedPhone}
                                </a>
                            </div>
                            {formattedName && <div>{formattedName}</div>}
                        </motion.div>
                        <motion.div
                            variants={phoneButtonVariants}
                            animate={isPhoneVisible ? "hidden" : "visible"}
                            transition={{
                                duration: 0.2
                            }}
                        >
                            <div>{maskedPhone}</div>
                            <div>Показать телефон</div>
                        </motion.div>
                    </div>
                }
            />
        </div>
    );
};
export default ContactButton;