import React, { useEffect } from "react";
import classes from "./MobileSelectInput.module.scss";
import CancelMaterialIcon from "../../../Svg/CancelMaterialIcon";
import { Option, OptionsGroup } from "@components/UI/Inputs/SelectInput/types";

export interface MobileSelectInputProps {
    onClose: () => void;
    value?: Option;
    options: Option[] | OptionsGroup[];
    onChange?: (e: any) => void;
    title: string;
}

const MobileSelectInput = ({
    onClose,
    options,
    onChange,
    value,
    title
}: MobileSelectInputProps) => {
    const ref = React.useRef<HTMLDivElement>(null);
    // useEffect(() => {
    //     // const targetElement = document.querySelector("#portal-content");
    //     console.log("ref", ref.current);
    //     disableBodyScroll(ref.current as HTMLElement);
    //     return () => {
    //         enableBodyScroll(ref.current as HTMLElement);
    //         clearAllBodyScrollLocks();
    //     };
    // }, [ref]);
    // console.log("options", options);

    const filterBySearch = (el: Option) => {
        return el.label.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    };
    const [search, setSearch] = React.useState("");
    const [showTitle, setShowTitle] = React.useState(true);
    const [isFocused, setIsFocused] = React.useState(false);

    return (
        <div className={classes.MobileSelectInput}>
            {showTitle && (
                <div className={classes.Header}>
                    <div onClick={onClose} className={classes.CloseBtn}>
                        <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M13.692 1.79511C14.1027 1.38445 14.1027 0.718653 13.692 0.307993C13.2814 -0.102656 12.6155 -0.102661 12.2049 0.307993L7 5.51289L1.79511 0.307993C1.38445 -0.102667 0.718647 -0.102661 0.307993 0.307993C-0.102661 0.718647 -0.102667 1.38445 0.307993 1.79511L5.51289 7L0.307993 12.2049C-0.102661 12.6155 -0.102667 13.2813 0.307993 13.692C0.718653 14.1027 1.38445 14.1027 1.79511 13.692L7 8.48711L12.2049 13.692C12.6156 14.1027 13.2814 14.1027 13.692 13.692C14.1027 13.2814 14.1027 12.6156 13.692 12.2049L8.48711 7L13.692 1.79511Z"
                                fill="#F86A6A"
                            />
                        </svg>
                    </div>
                    <h3>{title}</h3>
                </div>
            )}
            <div className={classes.Search}>
                <input
                    value={search}
                    onChange={(e) => {
                        // e.stopPropagation();
                        // e.preventDefault();
                        setSearch(e.target.value);
                    }}
                    onFocus={() => {
                        setIsFocused(true);
                    }}
                    onBlur={() => {
                        setTimeout(() => {
                            setIsFocused(false);
                        }, 200);
                    }}
                />
                {isFocused && (
                    <CancelMaterialIcon
                        style={{
                            zIndex: 999999
                        }}
                        onClick={() => {
                            setSearch("");
                        }}
                    />
                )}
            </div>

            <div ref={ref} className={classes.Content}>
                <ul className={classes.OptionsList}>
                    {options.map((el, index) => {
                        const groupOptions = (el as OptionsGroup)?.options?.filter(filterBySearch);
                        return groupOptions && groupOptions.length > 0 ? (
                            <li key={index} onClick={() => {}} className={classes.GroupName}>
                                <label>{el.label}</label>
                                <ul>
                                    {groupOptions.map((el, index) => (
                                        <li
                                            className={`${classes.Option} ${
                                                (el as Option).value === value?.value
                                                    ? classes.Selected
                                                    : ""
                                            }`}
                                            key={"secondd_" + index}
                                            onClick={() => {
                                                onChange && onChange(el);
                                                onClose();
                                            }}
                                        >
                                            {el.label}
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        ) : groupOptions && groupOptions.length === 0 ? (
                            <div className={classes.NoResults}>Ничего не найдено</div>
                        ) : (
                            <li
                                key={"sdfsdf" + index}
                                className={`${classes.Option} ${
                                    (el as Option).value === value?.value ? classes.Selected : ""
                                }`}
                                onClick={() => {
                                    onChange && onChange(el);
                                    onClose();
                                }}
                            >
                                {el.label}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};
export default MobileSelectInput;