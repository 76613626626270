import Link from "next/link";
import classes from "./Logo.module.scss";

interface LogoProps {
    width?: number;
    height?: number;
}
/* eslint-disable react/display-name */
export default ({ width, height }: LogoProps) => {
    return (
        <Link legacyBehavior href={"/"}>
            <svg
                width={width || 69}
                height={height || 36}
                viewBox="0 0 69 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0.487405 12.7952C0.218219 7.65887 4.16385 3.27679 9.30023 3.0076L57.3885 0.487403C62.5249 0.218217 66.9069 4.16385 67.1761 9.30023L67.9048 23.2048C68.174 28.3411 64.2284 32.7232 59.092 32.9924L4.60984 35.8477C3.00472 35.9318 1.63532 34.6988 1.5512 33.0937L0.487405 12.7952Z"
                    fill="#3B85F7"
                />
                <path
                    d="M11.4434 28.3219L10.4968 10.2592L13.7809 10.0871L14.7275 28.1498L11.4434 28.3219Z"
                    fill="white"
                />
                <path
                    d="M18.3667 27.9591L17.696 15.1604L20.9801 14.9883L21.6508 27.7869L18.3667 27.9591ZM19.2596 13.5777C18.6688 13.6087 18.155 13.4458 17.7181 13.0892C17.2986 12.7317 17.0726 12.2433 17.0402 11.624C17.0077 11.0047 17.1814 10.4954 17.5612 10.096C17.9584 9.69568 18.4524 9.48004 19.0432 9.44908C19.6514 9.41721 20.1652 9.58003 20.5847 9.93754C21.0042 10.295 21.2301 10.7835 21.2626 11.4027C21.295 12.022 21.1214 12.5314 20.7416 12.9308C20.3617 13.3302 19.8677 13.5458 19.2596 13.5777Z"
                    fill="white"
                />
                <path
                    d="M25.29 27.5962L24.3434 9.53355L27.6275 9.36144L28.1508 19.3475L28.62 19.3229L32.6471 14.3768L36.9216 14.1528L31.2774 20.6585L37.8009 26.9406L33.5785 27.1618L28.7769 22.3162L28.3077 22.3408L28.5741 27.4241L25.29 27.5962Z"
                    fill="white"
                />
                <path
                    d="M42.7127 26.6831L37.9238 14.1003L41.4165 13.9172L44.9602 24.1849L45.4293 24.1603L47.8804 13.5785L51.373 13.3954L47.9256 26.4099L42.7127 26.6831Z"
                    fill="white"
                />
                <path
                    d="M54.2814 26.0768L53.6107 13.2782L56.8947 13.106L57.5655 25.9047L54.2814 26.0768ZM55.1743 11.6955C54.5835 11.7264 54.0696 11.5636 53.6328 11.207C53.2133 10.8495 52.9873 10.3611 52.9549 9.74182C52.9224 9.12253 53.0961 8.61318 53.4759 8.21378C53.8731 7.81347 54.3671 7.59783 54.9579 7.56687C55.5661 7.535 56.0799 7.69782 56.4994 8.05533C56.9189 8.41284 57.1448 8.90124 57.1773 9.52053C57.2097 10.1398 57.0361 10.6492 56.6562 11.0486C56.2764 11.448 55.7824 11.6636 55.1743 11.6955Z"
                    fill="white"
                />
            </svg>
        </Link>
    );
};