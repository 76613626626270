import Link from "next/link";
import { useRouter } from "next/router";
import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import useOnClickOutside from "@src/hooks/userOutsideHook";
import { logoutUser, setAuthState } from "../../app/store/authSlice";
import { isPrivateRoute } from "../../components/Features/Auth/AuthCheck";
import classes from "./UserMenu.module.scss";

export interface UserMenuProps {
    close: () => void;
}

const UserMenu = ({ close }: UserMenuProps) => {
    const router = useRouter();
    const ref = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    useOnClickOutside(ref, (event: any) => {
        const isTargetButton = (event?.path || []).some((el: HTMLElement) => {
            if (el.getAttribute) {
                const id = el.getAttribute("id");
                return id === "user-menu-button";
            } else {
                return false;
            }
        });
        if (!isTargetButton) {
            close();
        }
    });

    return (
        <div ref={ref} className={classes.UserMenu}>
            <ul>
                <Link href="/profile">
                    <li>
                        <a>Профиль</a>
                    </li>
                </Link>
                <Link href="/my-ads">
                    <li>
                        <a>Мои объявления</a>
                    </li>
                </Link>
                <Link href="/saved">
                    <li>
                        <a>Избранное</a>
                    </li>
                </Link>
                <Link
                    onClick={(e) => {
                        e.preventDefault();
                        dispatch(logoutUser());
                        if (isPrivateRoute(router.pathname)) {
                            router.push("/");
                        }
                    }}
                    href="/"
                >
                    <li>
                        <a>Выйти</a>
                    </li>
                </Link>
            </ul>
        </div>
    );
};
export default UserMenu;