import React from "react";
import { useDispatch } from "react-redux";
import { setIsSoonVisible } from "../../../app/store/layoutSlice";
import AutoIcon from "@shared/ui/Icon/ui/AutoIcon";
import JobIcon from "../../../components/Svg/JobIcon";
import MarketsIcon from "../../../components/Svg/MarketsIcon";
import PropertyIcon from "../../../components/Svg/PropertyIcon";
import MainMenuButton from "../../../components/UI/Buttons/MainMenuButton/MainMenuButton";
import { MenuItem } from "../MenuItems";
import classes from "./DesktopMenu.module.scss";
import InsuranceIcon from "../../../components/Svg/InsuranceIcon";
import EstimateIcon from "../../../components/Svg/MenuIcons/EstimateIcon";

export interface DesktopMenuProps {
    className?: string;
    activeItem: MenuItem;
}

const DesktopMenu = (props: DesktopMenuProps) => {
    const dispatch = useDispatch();
    const activeItem = props.activeItem;

    return (
        <div className={`${classes.DesktopMenu} ${props.className}`}>
            <div>
                <MainMenuButton
                    title="Авто"
                    icon={AutoIcon}
                    link={"/catalog"}
                    isActive={activeItem.link === "/catalog"}
                />
            </div>
            <div>
                <MainMenuButton
                    title="Страхование"
                    icon={InsuranceIcon}
                    link={"/insurance"}
                    isActive={activeItem.link === "/insurance"}
                />
            </div>

            <div>
                {/* TODO: Временно скрываем страницу оценки авто.
                Для подробностей см. задачу LIKVI-1007 в Jira. */}
                {/*<MainMenuButton
                    title="Оценка авто"
                    icon={EstimateIcon}
                    link={"/analytics"}
                    isActive={activeItem.link.includes("/analytics")}
                />
                */}
            </div>

            <div
                className={classes.ClosedItems}
                onClick={() => {
                    dispatch(setIsSoonVisible(true));
                }}
            >
                <div>
                    <MainMenuButton
                        disabled={true}
                        title="Маркетплейсы"
                        icon={MarketsIcon}
                        link={"/marketplaces"}
                        isActive={activeItem.link === "/marketplaces"}
                    />
                </div>

                <div>
                    <MainMenuButton
                        disabled={true}
                        title="Работа"
                        icon={JobIcon}
                        link={"/job"}
                        isActive={activeItem.link === "/job"}
                    />
                </div>

                {/* <div>
                    <MainMenuButton
                        disabled={true}
                        title="Недвижимость"
                        icon={PropertyIcon}
                        link={"/property"}
                        isActive={activeItem.link === "/property"}
                    />
                </div> */}
            </div>

            {/* <li>
      <MainMenuButton
        title="Профиль"
        icon={MarketsIcon}
        link={'/profile'}
        isActive={activeItem.link === "/profile"}
      />
    </li> */}
        </div>
    );
};
export default DesktopMenu;