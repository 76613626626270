import Link from "next/link";
import React from "react";
import BreadCrumbsIcon from "../../Svg/BreadCrumbsIcon";
import classes from "./BreadCrumbs.module.scss";

export interface BreadCrumbsProps {
    pageTitle?: string;
}

const BreadCrumbs = ({ pageTitle }: BreadCrumbsProps) => {
    return (
        <div className={classes.BreadCrumbs}>
            <ul>
                <li>
                    <Link href={"/"}>Главная</Link>
                    <BreadCrumbsIcon />
                </li>
                <li>
                    <Link href={"/catalog"}>Каталог</Link>
                    <BreadCrumbsIcon />
                </li>
                {/* <li>
        <Link href={'/'}>Toyota Camry</Link><BreadCrumbsIcon />
      </li> */}
                <li>{pageTitle || "VIII (XV70)"}</li>
            </ul>
        </div>
    );
};
export default BreadCrumbs;