import React, { FC } from "react";

export const VkIcon: FC<{ className?: string }> = ({ className }) => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
            x="0.273438"
            y="0.272705"
            width="25.4545"
            height="25.4545"
            rx="12.7273"
            fill="#3B85F7"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.2855 9.37807C20.3917 9.02039 20.2855 8.75757 19.7803 8.75757H18.1094C17.6846 8.75757 17.4887 8.98461 17.3825 9.23497C17.3825 9.23497 16.5328 11.3274 15.3291 12.6865C14.9397 13.0799 14.7627 13.2051 14.5503 13.2051C14.4441 13.2051 14.2903 13.0799 14.2903 12.7223V9.37807C14.2903 8.94886 14.167 8.75757 13.813 8.75757H11.1874C10.9219 8.75757 10.7622 8.95678 10.7622 9.14557C10.7622 9.55246 11.364 9.64629 11.4261 10.7909V13.2767C11.4261 13.8217 11.3286 13.9205 11.1162 13.9205C10.5498 13.9205 9.17196 11.8188 8.35478 9.41382C8.19464 8.94639 8.03401 8.75757 7.60699 8.75757H5.93615C5.45876 8.75757 5.36328 8.98461 5.36328 9.23497C5.36328 9.68207 5.92973 11.8996 8.00078 14.8325C9.38147 16.8355 11.3268 17.9212 13.0969 17.9212C14.159 17.9212 14.2904 17.6801 14.2904 17.2647V15.7509C14.2904 15.2686 14.391 15.1723 14.7273 15.1723C14.9751 15.1723 15.3999 15.2975 16.3912 16.2632C17.5241 17.4078 17.7109 17.9212 18.3481 17.9212H20.019C20.4963 17.9212 20.735 17.6801 20.5973 17.2042C20.4467 16.7299 19.9058 16.0417 19.1881 15.226C18.7986 14.761 18.2145 14.2603 18.0374 14.0099C17.7896 13.688 17.8604 13.5449 18.0374 13.2588C18.0374 13.2588 20.0731 10.3616 20.2855 9.37807Z"
            fill="white"
        />
    </svg>
);