import React, { FC } from "react";

export const CopyLinkIcon: FC<{ className?: string }> = ({ className }) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.1418 2.32156C11.2232 2.32156 10.3622 2.66544 9.70181 3.32482L8.26658 4.75792C7.80725 5.21655 7.08963 5.21655 6.6303 4.75792C6.17098 4.29928 6.17098 3.58273 6.6303 3.12409L8.03688 1.691C9.12771 0.601796 10.5629 0 12.0843 0C13.6056 0 15.0695 0.602001 16.1317 1.691C17.2225 2.7802 17.8252 4.21329 17.8252 5.73237C17.8252 7.25145 17.2223 8.7131 16.1317 9.77374L14.7251 11.2068C14.4955 11.4362 14.2086 11.5507 13.9214 11.5507C13.6343 11.5507 13.3474 11.4362 13.1177 11.2068C12.6583 10.7482 12.6583 10.0316 13.1177 9.573L14.5529 8.13991C15.2131 7.48074 15.5577 6.62083 15.5577 5.70355C15.5577 4.78627 15.2133 3.92657 14.5529 3.26719C13.9214 2.6654 13.0602 2.32151 12.1418 2.32151L12.1418 2.32156Z"
            fill="#3B85F7"
        />
        <path
            d="M3.01374 6.76415C3.47306 6.30551 4.19068 6.30551 4.65001 6.76415C5.10933 7.22279 5.10933 7.93934 4.65001 8.39798L3.24343 9.80246C2.58327 10.4616 2.23866 11.3215 2.23866 12.2388C2.23866 13.1561 2.58306 14.0158 3.24343 14.6752C3.90359 15.3343 4.76477 15.6784 5.68343 15.6784C6.60209 15.6784 7.46307 15.3346 8.12343 14.6752L9.55867 13.2707C10.018 12.8121 10.7356 12.8121 11.1949 13.2707C11.6543 13.7293 11.6543 14.4459 11.1949 14.9045L9.78835 16.309C8.69753 17.3982 7.26229 18 5.74094 18C4.21959 18 2.75575 17.398 1.69352 16.309C0.602699 15.2198 0 13.7867 0 12.2676C0 10.7486 0.602899 9.2869 1.69352 8.22626L3.01374 6.76415Z"
            fill="#3B85F7"
        />
        <path
            d="M6.25736 11.6367C6.48705 11.866 6.77396 11.9806 7.06109 11.9806C7.37684 11.9806 7.66397 11.866 7.89363 11.6369L11.5392 7.99682C11.9985 7.53818 11.9985 6.82163 11.5392 6.36299C11.0799 5.90436 10.3622 5.90436 9.90291 6.36299L6.25736 10.0029C5.79804 10.4615 5.79804 11.1781 6.25736 11.6367Z"
            fill="#3B85F7"
        />
    </svg>
);