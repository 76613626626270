import React from "react";
import SvgInterface from "@shared/lib/interfaces/SvgInterface";

export default React.memo(function Logo({ width, height, color, ...other }: SvgInterface) {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.73224 19.2678C4.26333 18.799 4.00001 18.163 4.00001 17.5L4.00001 17.1924C3.99827 16.457 4.14894 15.7292 4.44244 15.055C4.80078 14.2312 5.35936 13.5101 6.06753 12.9575C6.52057 12.6033 6.99945 12.2831 7.49994 11.9999C6.75341 11.0045 6.40482 9.7664 6.52235 8.52773C6.6399 7.28889 7.21503 6.13849 8.13561 5.30125C9.05602 4.46388 10.2556 4 11.5 4H11.695C12.9476 4.05339 14.1348 4.57304 15.0239 5.45701C15.9129 6.34099 16.4393 7.52523 16.5 8.77751V9.00003C16.5 9.35731 16.3094 9.68744 16.0001 9.86601C15.6906 10.0446 15.3093 10.0446 15 9.86601C14.6906 9.68744 14.5 9.35735 14.5 9.00003V8.86244C14.4627 8.11239 14.1469 7.40316 13.6141 6.87373C13.0813 6.34431 12.3704 6.03268 11.62 6.00007H11.5C10.6408 5.99935 9.82261 6.36699 9.2527 7.00994C8.6828 7.65293 8.41582 8.50918 8.51957 9.36208C8.62332 10.215 9.08752 10.9825 9.79502 11.4701C9.99348 11.6089 10.1347 11.815 10.1926 12.0503C10.2505 12.2855 10.221 12.5336 10.1098 12.7488C9.99854 12.964 9.81282 13.1315 9.58751 13.22C8.76474 13.5451 7.99398 13.9891 7.29992 14.5375C6.85523 14.8851 6.50386 15.338 6.2775 15.855C6.09353 16.2769 5.99901 16.7324 6.00006 17.1926V17.5C6.00006 17.7763 6.22378 18 6.50001 18H11C11.3572 18 11.6873 18.1906 11.866 18.5001C12.0446 18.8095 12.0446 19.1907 11.866 19.5C11.6873 19.8094 11.3572 20 11 20H6.50001C5.83698 20 5.20099 19.7367 4.73224 19.2678Z"
                fill="#818BA1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.3084 13.0291C13.9702 12.2231 14.957 11.7542 16 11.75L16.0001 11.7499C16.8572 11.7494 17.6849 12.0633 18.3259 12.6321C18.9669 13.2012 19.3771 13.9856 19.4782 14.8368C19.5795 15.688 19.3649 16.5466 18.875 17.2501L19.78 18.3826C19.9902 18.6623 20.0388 19.0315 19.9084 19.356C19.7779 19.6807 19.4873 19.9135 19.1421 19.97C18.7969 20.0265 18.4472 19.8985 18.22 19.6326L17.3125 18.5001C16.3441 18.8871 15.2535 18.8222 14.3378 18.3228C13.4221 17.8235 12.7769 16.9418 12.5779 15.918C12.3787 14.8943 12.6466 13.8351 13.3084 13.0291ZM14.9392 14.1895C14.6579 14.4708 14.4999 14.8523 14.4999 15.2501C14.4999 15.6479 14.6579 16.0294 14.9392 16.3107C15.2205 16.592 15.6021 16.75 16 16.75C16.3978 16.75 16.7794 16.592 17.0607 16.3107C17.3419 16.0294 17.4999 15.6479 17.4999 15.2501C17.4999 14.8523 17.3419 14.4707 17.0607 14.1895C16.7794 13.908 16.3978 13.75 16 13.75C15.6021 13.75 15.2205 13.908 14.9392 14.1895Z"
                fill="#818BA1"
            />
        </svg>
    );
});